import React, { useContext, useState } from 'react'
import { JsonContext } from '../../../context/JsonProvider';


interface HashBoxProps{
    hashType: string,
    giveHash: (hashType: string) => void,
    hashSelected: string,
    disabled: boolean,
}

const HashButton = ({hashType, giveHash, hashSelected, disabled} :HashBoxProps) => {

    console.log(disabled);

    const { setForm, form } = useContext(JsonContext);


    const handleClick = () => {
        giveHash(hashType)
    }


    return (
        <button disabled={disabled} className={`hash-button${hashType === hashSelected ? '-active' : ''}`} onClick={() => handleClick()}>
            <span>
                {hashType}
            </span>
        </button>
    )
}

export default HashButton
