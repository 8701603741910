import React from 'react'

const Footer = () => {
    return (
        <div className="footer-container">
            <span className="powered-by">powered by <a href="https://st.foundation/">Software Transparency Foundation</a></span>
            <hr className="hr-footer-divider"/>
            <span className="copyright">&copy; Copyright 2018-2021 / Software Transparency fundation / All Rights Reserved</span>
        </div>
    )
}

export default Footer
