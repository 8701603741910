import React, { useContext, useEffect } from 'react'
import { JsonContext } from '../../../context/JsonProvider'

interface TokenInputProps {
    tokenValue: string,
}

const TokenInput = ({tokenValue}: TokenInputProps) => {
    
    const {setToken} = useContext(JsonContext);

    useEffect(() => {
        setToken(tokenValue);
    }, [tokenValue])

    return (
        <div>
            <div className="token-id-label">
                Your Token Id
            </div>
            <div className="blockchain-explorer">{
                tokenValue ? (
                    <input type="text" className="hash-text-input-disabled" name="token" id="a" placeholder={tokenValue} readOnly />
                ) : (
                    <input onChange={(e) => {setToken(e.target.value)}} type="text" className="hash-text-input" name="token" id="a" placeholder="Token Id" />
                )
            }
            </div>
        </div>
    )
}

export default TokenInput
