import React from 'react'
import HashButton from '../../HashButton'

interface HashTypeProps {
    children: any
}

const HashType = ({children}: HashTypeProps) => {
    return (
        <div className="hash-type">
                    <span className="tiny-label">
                    Hash type
                    </span>
                    <div className="hash-type-boxes">
                        {children}
                    </div>
        </div>
    )
}

export default HashType
