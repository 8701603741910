import React, { useState } from 'react'

interface InputRadiusProps {
    labelText: string,
}   

const InputRadius = ({labelText }: InputRadiusProps)  => {
    return (
        <div  className="input-radius-container">
            {/* <div className={`custom-radius-button${active ? "-active" : ""}`} >
                <div></div>
            </div> */}
            <span className={`label-radius-active`}>{labelText}</span>
        </div>
    )
}

export default InputRadius;