import React, {useContext, useEffect} from 'react'
import { JsonContext } from '../../../../context/JsonProvider'
import Input from '../../../Input/Input'
import InputRadius from '../../../InputRadius/InputRadius'
import HashButton from '../HashButton'
import HashType from './components/HashType'
import SbomHash from './components/SbomHash';



interface CurrentSbomProps {
    hashAndType: any
}

const CurrentSbom = ({hashAndType}: CurrentSbomProps) => {
   
    console.log(hashAndType);

    const {hash, type} = hashAndType;

    const { setForm, form } = useContext(JsonContext);

    useEffect(() => {
        setForm({
            ...form,
            sbom_data: [
                {
                    ...form.sbom_data[0],
                    checksum: hash.trim(),
                    algorithm: type.toUpperCase(),
                }
            ]
        })
    }, [hash, type])

    const addHashType = (algorithm: string) => {
        setForm({
            ...form,
            sbom_data: [
                {
                    ...form.sbom_data[0],
                    algorithm: algorithm,
                }
            ]
        })
    }




    return (
        <>
        <div className="current-sbom-container">
                <span className="title">SBOM Data*</span>
                <div className="sbom-hash">
            <span className="tiny-label">
                    SBOM hash
            </span>

            {
                hash ? (<textarea readOnly className="hash-text-area-disabled" name="a" id="a" cols={20} rows={4} placeholder={hash} />) : (
                    <textarea onChange={(e) => setForm({
                        ...form,
                        sbom_data: [
                            {
                                ...form.sbom_data[0],
                                checksum: e.target.value.trim(),
                            }
                        ]
                    })}  className="hash-text-area" name="a" id="a" cols={20} rows={4} placeholder="Hash" />
                )
            }
            
        </div>
                    <HashType>
                        <HashButton disabled={type} hashType="SHA224" giveHash={(hashType) => {
                            addHashType(hashType)
                        }} hashSelected={form.sbom_data[0]?.algorithm} />
                        <HashButton disabled={type} hashType="SHA256" giveHash={(hashType) => {
                            addHashType(hashType)
                        }} hashSelected={form.sbom_data[0]?.algorithm} />
                        <HashButton disabled={type} hashType="SHA384" giveHash={(hashType) => {
                            addHashType(hashType)
                        }} hashSelected={form.sbom_data[0]?.algorithm} />
                    </HashType>
        </div>  
        </>
    )
}

export default CurrentSbom
