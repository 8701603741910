import React, {useContext} from 'react'
import { JsonContext } from '../../../../../context/JsonProvider'

interface SbomHashProps { 
    children: any,
}

const SbomHash = ({children}: SbomHashProps) => {


    return (
        <div className="sbom-hash">
            <span className="tiny-label">
                    SBOM hash
            </span>
            {children}
        </div>
    )
}

export default SbomHash
