import React from 'react'
import { useContext } from 'react'
import { JsonContext } from '../../../context/JsonProvider';

const PublishBlockchain = () => {

    const { postData } = useContext(JsonContext);

    return (
       <div className="container-pb">
            <button onClick={() => postData()} className="publish-blockchain">
                <span>Publish to Blockchain</span>
            </button>
       </div>
    )
}

export default PublishBlockchain
