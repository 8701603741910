import React, {useContext} from 'react'
import { JsonContext } from '../../context/JsonProvider';

const JsonViewer = () => {

  const { form } = useContext(JsonContext);

  const { parent_sboms, sbom_data } = form;
  console.log(parent_sboms);

  return (
    <pre className="pre-container">
      {JSON.stringify(form, null, 2)}
    </pre>
  )
}

export default JsonViewer
