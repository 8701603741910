import React, { useState, useEffect, useContext } from 'react'
import { JsonContext } from '../../../../context/JsonProvider';
import Input from '../../../Input/Input'
import InputRadius from '../../../InputRadius/InputRadius'
import AddIcon from '@material-ui/icons/Add';
import HashType from '../CurrentSbom/components/HashType';
import SbomHash from '../CurrentSbom/components/SbomHash';
import HashButton from '../HashButton';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';


const ParentSbom = () => {
    const { setForm, form } = useContext(JsonContext);

    const [items, setItems] = useState<any[]>([]);
    const [url, setUrl] = useState<any[]>([]);


    const addItem = () => {
        setItems([...items, {
            checksum: '',
            algorithm: 'SHA224',
        }])
    }

    const addTxid = () => {
        setUrl([...url, {
            blockchain_explorer: '',
        }])
    }

    const handleUrl = (blockchain_explorer: string, index: number) => {
        const newUrl = [...url];
        newUrl[index].blockchain_explorer = blockchain_explorer.trim();
        setUrl(newUrl);
    }

    const removeUrl = (index: number) => {
        const newUrl = [...url];
        newUrl.splice(index, 1);

        setUrl(newUrl);
    }


    const handleChecksum = (value: string, index: number) => {
        const newItems = [...items];
        newItems[index].checksum = value.trim();

        setItems(newItems);
    }

    const handleAlgorithm = (value: string, index: number) => {
        const newItems = [...items];
        newItems[index].algorithm = value;

        setItems(newItems);
    }

    const removeItem = (index: number) => {
        const newItems = [...items];
        newItems.splice(index, 1);

        setItems(newItems);
    }



    useEffect( () => {
        setForm({
            ...form,
            parent_sboms: [...items, ...url],
        })
    }, [items, url]);


    return (
        <>
            <div className="parent-sbom-container">
                <span className="title">Parent SBOM (optional)</span>
                <div className="radius-container">
                    {/* <div className="input-label-container">
                        <InputRadius labelText="Parent SBOM hash" />
                    </div> */}

                    {/* {items.length === 0 ? <p></p> : <p>You added {items.length} sbom hash.</p>} */}

                    

                    {items.map((item, index) => (
                        <>
                            <div className="parent-sbom-hash" key={index}>
                                <div className="actions-aditionals">
                                    <span className="tiny-label"></span>
                                    <IconButton onClick={() => removeItem(index)} size="small">
                                        <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                                <div className="sbom-hash-card">
                                <SbomHash>
                                <textarea onChange={(e) => handleChecksum(e.currentTarget.value, index)} className="hash-text-area" name="a" id="a" cols={20} rows={4} placeholder="Hash" />
                                </SbomHash>
                                <HashType>
                                    <HashButton disabled={false} hashType="SHA224" giveHash={(hashType) => {handleAlgorithm(hashType, index)}} hashSelected={items[index]?.algorithm}/>
                                    <HashButton disabled={false} hashType="SHA256" giveHash={(hashType) => {handleAlgorithm(hashType, index)}} hashSelected={items[index]?.algorithm}/>
                                    <HashButton disabled={false} hashType="SHA384" giveHash={(hashType) => {handleAlgorithm(hashType, index)}} hashSelected={items[index]?.algorithm}/>
                                </HashType>
                                </div>
                            </div>
                        </>
                    ))}
                    
                    
                </div>
                {
                    url.map((item, index) => (
                        <div className="parent-blockchain-url" key={index}>
                            <div className="input-label-container">
                                
                                <div className="actions-aditionals">
                                <InputRadius labelText="Blockchain URL to Parent SBOM" />
                                    <IconButton onClick={() => removeUrl(index)} size="small">
                                        <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="blockchain-explorer">
                                <input onChange={(e) => {handleUrl(e.target.value, index)}} type="text" className="hash-text-input" name="blockchain_explorer" id="a" placeholder="URL" />
                                {/* <AddButton label={'Add URL'} /> */}
                            </div>
                        </div>
                    ))

                }
                <div className="add-buttons-container">
                        <button onClick={addItem} className="add-file-button">
                            <span>
                                Add Hash
                            </span>
                            <AddIcon />
                        </button>

                        <button onClick={addTxid} className="add-file-button">
                            <span>
                                Add TXID
                            </span>
                            <AddIcon />
                        </button>
                    </div>
                
                {/* {
                        clicked.sbom ?
                        (
                            <div className="blockchain-explorer">
                                 <span className="tiny-label">Blockchain Explorer URL</span>
                                 <textarea className="hash-text-area" name="a" id="a" cols={20} rows={4} placeholder="URL" />
                                 <AddButton label={'Add URL'} />
                            </div>
                        ) : null 
                    } */}
            </div>
        </>
    )
}

export default ParentSbom

