import React, { useContext } from 'react'
import { useState } from 'react'
import { JsonContext } from '../../../context/JsonProvider'
import DeleteIcon from '@material-ui/icons/Delete';
import HashType from './CurrentSbom/components/HashType'
import SbomHash from './CurrentSbom/components/SbomHash'
import HashButton from './HashButton'
import AddIcon from '@material-ui/icons/Add';
import { useEffect } from 'react'
import { IconButton } from '@material-ui/core';


const AdditionalFiles = () => {
    const { setForm, form } = useContext(JsonContext);

    const [files, setFiles] = useState<any[]>([]);

    const addFile = () => {
        setFiles([...files, {
            checksum: '',
            algorithm: 'SHA224',
        }])
    }

    const handleChecksum = (value: string, index: number) => {
        const newFiles = [...files];
        newFiles[index].checksum = value.trim();
        setFiles(newFiles);
    }

    const handleAlgorithm = (value: string, index: number) => {
        const newFiles = [...files];
        newFiles[index].algorithm = value.trim();

        setFiles(newFiles);
    }

    const removeFile = (index: number) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);

        setFiles(newFiles);
    }

    useEffect( () => {
        setForm({
            ...form,
            sbom_data: [
                {   
                    ...form.sbom_data[0],
                },
                ...files
            ]
        })
    }, [files]);


    return (
        <div className="additional-files">
            {/* <span className="title">
                Additional Files
            </span> */}
            {/* {files.length === 0 ? <p></p> : <p>You added {files.length} file(s).</p>} */}

            {files.map((file, index) => (
            <>
                <div className="parent-sbom-hash" key={index}>
                    <div className="actions-aditionals">
                        <span className="tiny-label"></span>
                        <IconButton onClick={() => removeFile(index)} size="small">
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                    <div className="sbom-hash-card">

                            <SbomHash>
                                <textarea onChange={(e) => handleChecksum(e.currentTarget.value, index)} className="hash-text-area" name="a" id="a" cols={20} rows={4} placeholder="Hash" />
                            </SbomHash>
                            <HashType>
                                <HashButton disabled={false} hashType="SHA224" giveHash={(hashType) => {handleAlgorithm(hashType, index)}} hashSelected={files[index]?.algorithm}/>
                                <HashButton disabled={false} hashType="SHA256" giveHash={(hashType) => {handleAlgorithm(hashType, index)}} hashSelected={files[index]?.algorithm}/>
                                <HashButton disabled={false} hashType="SHA384" giveHash={(hashType) => {handleAlgorithm(hashType, index)}} hashSelected={files[index]?.algorithm}/>
                            </HashType>
                    </div>
                </div>
            </>
             ))}

            {/* <AddButton label={'Add File'} onClick={addFile} /> */}
            <button  onClick={addFile} className="add-file-button">
                <span>
                    Add Hash
                </span>
                <AddIcon />
            </button>
        </div>
    )
}

export default AdditionalFiles
