import React, { useEffect } from 'react';
import './App.global.scss';
import Container from './components/Container/Container';
import TxViewer from './components/TxViewer/TxViewer';
// import JsonViewer from './components/JsonViewer/JsonViewer';
import JsonProvider from './context/JsonProvider';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


function App() {
  return (
    <JsonProvider>
      <div className="app">
        <Router>
          <Switch>
            <Route path="/" exact>
              <Container />
            </Route>
            <Route path="/:id" exact>
              <TxViewer />
            </Route>
          </Switch>
        </Router>
      </div>
    </JsonProvider>
  );
}

export default App;
