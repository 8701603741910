import React, { useEffect, useState } from 'react'
import AdditionalFiles from './components/AdditionalFiles'
import CurrentSbom from './components/CurrentSbom/CurrentSbom'
import ParentSbom from './components/ParentSbom/ParentSbom'
import PublishBlockchain from './components/PublishBlockchain'
import TokenInput from './components/TokenInput';
import { useLocation } from 'react-router';
import queryString from 'query-string';

const Form = () => {

    const { search } = useLocation();
    console.log(search);
    const { hash, type, token } = queryString.parse(search);
    console.log(hash, type);
  
    const [hashAndType, setHashAndType] = useState<object>({ hash: '', type: '' });
  
    useEffect(() => {
        if (hash && type) {
            if (type === 'sha224' || type === 'sha256' || type === 'sha384') {
                setHashAndType({hash, type});
            }
        }
    }, [hash, type]);

    const [tokenValue, setTokenValue] = useState<any>('');
    
    useEffect(() => {
        if (token) {
            setTokenValue(token);
        }
    }, [token]);

    return (
        <>
        <div className="form">
            <ParentSbom/>
            <hr className="divider" />
            <CurrentSbom hashAndType={hashAndType}/>
            <AdditionalFiles />
            <TokenInput tokenValue={tokenValue} />
            <PublishBlockchain />
        </div>
        </>
    )
}

export default Form
