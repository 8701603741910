import React, { useContext } from 'react'
import { JsonContext } from '../../context/JsonProvider';
import Form  from '../Form/Form';
import JsonViewer from '../JsonViewer/JsonViewer';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';

const Container = () => {

    
    const { form, alert, message, id } = useContext(JsonContext);

    return (
        <>
        <div className="container">
            <div className={`alert${alert.uploading ? '-active-uploading' : ''}${alert.uploaded ? '-active' : ''}${alert.error ? '-active-error' : ''}`}>
                <span>{message}</span>
                {
                    alert.uploading ? (<div className="container-message-spinner">
                        <div className="spinner"></div>
                        <span>Waiting for a response</span>
                    </div>) : null
                }
                {
                    alert.uploaded ? (<div>
                        <span>Id: <Link to={`/${id}`} >{id}</Link></span>
                        <IconButton onClick={() => navigator.clipboard.writeText(id)}>
                            <FileCopyIcon />
                        </IconButton>
                    </div>) : null
                }
            </div>

            <div className="main-container">
                <div className="form-container">
                    <div className="title-container">
                        <span className="title-text">SBOM Broadcasting</span>
                    </div>
                    <Form />
                </div>
                <div className="json-container">
                    <div className="">
                        <span className="title-text">Your generated JSON file</span>
                    </div>
                    <JsonViewer />
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default Container
