import React, { useContext, useEffect, useState } from 'react'
import { JsonContext } from '../../context/JsonProvider';
import Form  from '../Form/Form';
import JsonViewer from '../JsonViewer/JsonViewer';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IconButton } from '@material-ui/core';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams
} from "react-router-dom";

interface IParams { id: string; }

const TxViewer = () => {
        
    const { form, alert, message, id, setAlert, setMessage } = useContext(JsonContext);

    const {REACT_APP_BLOCKCHAIN_API_URL_GET} = process.env;
  
    const params = useParams<IParams>();

    const [data, setData] = useState('')

    useEffect(() => {    
        setMessage('');
        setAlert({
            uploading: false,
            uploaded: false,
            error: false,
        })
    }, [])


    useEffect(() => {
        console.log(params.id);
        fetch(`${REACT_APP_BLOCKCHAIN_API_URL_GET}${params.id}`).then(res => {
            return res.json();
        }).then(data => {
            setData(data);
        }).catch(err => {
            console.log(err);
        })
    }, [params])


    return (
        <div className="container">
        <div className={`alert${alert.uploading ? '-active-uploading' : ''}${alert.uploaded ? '-active' : ''}${alert.error ? '-active-error' : ''}`}>
            <span>{message}</span>
            {
                alert.uploading ? (<div className="container-message-spinner">
                    <div className="spinner"></div>
                    <span>Waiting for a response</span>
                </div>) : null
            }
            {
                alert.uploaded ? (<div>
                    <span>Id: {id}</span>
                    <IconButton onClick={() => navigator.clipboard.writeText(id)}>
                        <FileCopyIcon />
                    </IconButton>
                </div>) : null
            }
        </div>

        <div className="main-container">
        <div className="form-container">
                    <div className="title-container">
                        <span className="title-text">SBOM Broadcasting</span>
                    </div>
                </div>
            
        </div>
        <pre className="pre-container">
                {
                    JSON.stringify(data, null, 2)
                }
        </pre>
    </div>
    )
}

export default TxViewer
