
import React, {useState, createContext, useEffect} from 'react';


export const JsonContext = createContext()

const JsonProvider = (props) => {

    const {REACT_APP_BLOCKCHAIN_API_URL_POST} = process.env;

    const [form, setForm] = useState({
        type: "sbom",
        parent_sboms: [],
        sbom_data: [
            {
              checksum: '',
              algorithm: 'SHA224'
            }
        ]
    }); 

    const [token, setToken] = useState('');

    const [alert, setAlert] = useState({
        uploading: false,
        uploaded: false,
        error: false,
    });
    const [message, setMessage] = useState('');

    const [response, setResponse] = useState({});
    const [id, setId] = useState('');

    useEffect(() => {
        console.log(response)
        setId(response.id);
    }, [response]);


    const postData = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

        if (form.sbom_data[0].checksum.trim() === '' || form.sbom_data[0].algorithm === '') 
        {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            setMessage('Please fill all required fields')
            setAlert({
                uploading: false,
                uploaded: false,
                error: true,
            })
            setTimeout(() => {
                setMessage('')
                setAlert({
                    uploading: false,
                    uploaded: false,
                    error: false,
                })
            } , 5000);
        } else {
            setAlert({
                uploading: true,
                uploaded: false,
                error: false,
            })
            const newFormData = new FormData();
            newFormData.append('data', JSON.stringify(form));
            newFormData.append('token', token);
            fetch(REACT_APP_BLOCKCHAIN_API_URL_POST, {
                method: 'POST', 
                headers: {
                    'Accept': 'application/json',
                },
                body: newFormData
            })  
            .then(res => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
            if (res.ok) {
                setMessage('Sbom succesfully added to blockchain');
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
                setAlert({
                    uploading: false,
                    uploaded: true,
                    error: false,
                })
                
                return res.json();
            } else {
                alert('Error: ' + res.status)
                throw new Error('The Json cannot be send')
            }
        }).then(json => {
            setResponse(json);  
            console.log(id);
        }).catch(err => {
            setMessage('There was an error, please check your token');
            setAlert({
                uploading: false,
                uploaded: false,
                error: true,
            })
            setTimeout(() => {
                setMessage('');
                setAlert({
                    uploading: false,
                    uploaded: false,
                    error: false,
                })
            } , 10000);
            console.log(err);
        });
        }
    }


    return (
        <JsonContext.Provider value={{setForm, form, postData, alert, message, id, setToken, setAlert, setMessage}}>
            {props.children}
        </JsonContext.Provider>   
    )
}

export default JsonProvider
    